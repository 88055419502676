import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "autobox",
  class: "absolute bottom-full left-0 max-h-md-image overflow-y-auto bg-white border border-primary cursor-pointer"
}
const _hoisted_2 = ["id", "onMousedown", "onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isInputFocus && _ctx.options.length > 0 && !_ctx.completed && !_ctx.optionsLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, i) => {
          return (_openBlock(), _createElementBlock("p", {
            key: `autocomplete-${i}`,
            id: `autocomplete-${i}`,
            class: _normalizeClass(["p-standard border-b border-primary transition-colors duration-200 last:border-none", {
        'bg-primary text-primary-contrast': _ctx.selection === i
      }]),
            onMousedown: _withModifiers(($event: any) => (_ctx.select(i)), ["prevent"]),
            onMouseenter: ($event: any) => (_ctx.selection = i)
          }, _toDisplayString(item), 43, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}