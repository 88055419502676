import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87aad808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-body-width w-body-width mx-auto" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col hd:flex-row"
}
const _hoisted_3 = {
  id: "location-check",
  class: "relative flex flex-col justify-between p-standard rounded-3xl p-lg pb-xl bg-background hd:py-xl hd:px-2xl hd:flex-row"
}
const _hoisted_4 = {
  key: 0,
  class: "absolute bottom-0 mb-lg text-accent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_switch_control = _resolveComponent("switch-control")!
  const _component_InputControl = _resolveComponent("InputControl")!
  const _component_ButtonControl = _resolveComponent("ButtonControl")!
  const _component_pulse_loader = _resolveComponent("pulse-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showLabel)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createVNode(_component_switch_control, {
            class: "mx-auto w-1/2 hd:ml-xl hd:w-auto",
            modelValue: $setup.shopMode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.shopMode) = $event)),
            "on-label": "Business",
            "on-value": "business",
            "off-label": "Privat",
            "off-value": "private"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      ($setup.town)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Ort: " + _toDisplayString($setup.town), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_InputControl, {
        class: "mr-0 mt-0 hd:mr-lg hd:mt-lg",
        name: "PLZ",
        readonly: $setup.checkComplete,
        "autocomplete-options": $setup.zipCodeResults,
        "options-loading": $setup.zipLoading,
        debounce: $setup.zipCodeDebounce,
        modelValue: $setup.zip,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.zip) = $event)),
        onInput: _cache[2] || (_cache[2] = ($event: any) => ($setup.zipAutocompleted = false)),
        onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.zipFocussed = false)),
        onFocus: _cache[4] || (_cache[4] = ($event: any) => ($setup.zipFocussed = true)),
        onAutocompleteSelected: $setup.handleZipAutocomplete
      }, null, 8, ["readonly", "autocomplete-options", "options-loading", "debounce", "modelValue", "onAutocompleteSelected"]),
      _createVNode(_component_InputControl, {
        class: "flex-grow",
        name: "Strasse",
        placeholder: "Strasse",
        "autocomplete-options": $setup.addressResults,
        "options-loading": $setup.zipLoading,
        debounce: $setup.streetDebounce,
        modelValue: $setup.street,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.street) = $event)),
        onInput: _cache[6] || (_cache[6] = ($event: any) => ($setup.streetAutocompleted = false)),
        onFocus: _cache[7] || (_cache[7] = ($event: any) => ($setup.streetFocussed = true)),
        onBlur: _cache[8] || (_cache[8] = ($event: any) => ($setup.streetFocussed = false)),
        onAutocompleteSelected: $setup.handleAddressAutocomplete,
        readonly: $setup.checkComplete,
        disabled: !$setup.zipAutocompleted
      }, null, 8, ["autocomplete-options", "options-loading", "debounce", "modelValue", "onAutocompleteSelected", "readonly", "disabled"]),
      _createVNode(_component_InputControl, {
        class: "ml-0 hd:ml-lg",
        name: "Hausnummer",
        modelValue: $setup.houseNumber,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.houseNumber) = $event)),
        onInput: _cache[10] || (_cache[10] = ($event: any) => {
          $setup.housenumberAutocompleted = false;
          $setup.streetDebounce($event.target.value);
        }),
        onFocus: _cache[11] || (_cache[11] = ($event: any) => ($setup.houseNumberFocussed = true)),
        onBlur: _cache[12] || (_cache[12] = ($event: any) => ($setup.houseNumberFocussed = false)),
        onAutocompleteSelected: $setup.handleAddressAutocomplete,
        "autocomplete-options": $setup.addressResults,
        readonly: $setup.checkComplete,
        disabled: !$setup.zipAutocompleted,
        debounce: $setup.streetDebounce
      }, null, 8, ["modelValue", "onAutocompleteSelected", "autocomplete-options", "readonly", "disabled", "debounce"]),
      (!$setup.loading)
        ? (_openBlock(), _createBlock(_component_ButtonControl, {
            key: 1,
            class: "confirm max-w-4/5",
            width: "300px",
            label: 
          $setup.checkComplete ? 'ZURÜCKSETZEN' : $setup.loading ? 'PRÜFUNG...' : 'ADRESSE PRÜFEN'
        ,
            disabled: !$setup.formComplete || $setup.loading,
            onClick: $setup.onCheckLocation
          }, null, 8, ["label", "disabled", "onClick"]))
        : (_openBlock(), _createBlock(_component_pulse_loader, {
            key: 2,
            class: "confirm flex justify-center",
            loading: $setup.loading,
            color: "#ea3d00"
          }, null, 8, ["loading"]))
    ])
  ]))
}