
import { defineComponent, ref, computed, watchEffect } from "vue";
export default defineComponent({
  name: "AutocompleteBox",
  props: {
    options: { type: Array, default: () => [] },
    optionsLoading: { type: Boolean, default: false },
    inputElement: { type: HTMLInputElement }
  },
  emits: ["selected"],
  setup(props, context) {
    // console.log("Autocomplete input: ", props);
    const selection = ref(0);
    const isInputFocus = ref(false);
    const completed = ref(false);

    const next = () => {
      if (props.options.length === 0) return;
      if (selection.value + 1 < props.options.length) ++selection.value;
      else selection.value = 0;
      const selectedElement = document.getElementById(
        `autocomplete-${selection.value}`
      );
      if (selectedElement)
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start"
        });
    };
    const previous = () => {
      if (props.options.length === 0) return;
      if (selection.value > 0) --selection.value;
      else selection.value = props.options.length - 1;
      const selectedElement = document.getElementById(
        `autocomplete-${selection.value}`
      );
      if (selectedElement)
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start"
        });
    };
    const select = (index: number) => {
      context.emit("selected", props.options[index]);
      completed.value = true;
    };
    const registerKeyHandlers = (inputElement: HTMLInputElement) => {
      if (inputElement) {
        inputElement.addEventListener("focus", event => {
          isInputFocus.value = true;
        });
        inputElement.addEventListener("blur", event => {
          isInputFocus.value = false;
        });
        inputElement.addEventListener("input", event => {
          completed.value = false;
        });
        inputElement.addEventListener("keydown", event => {
          let handled = false;
          switch (event.key) {
            case "ArrowUp":
              previous();
              handled = true;
              break;
            case "ArrowDown":
              next();
              handled = true;
              break;
            case "Enter":
              if (selection.value > 0) {
                select(selection.value);
                selection.value = -1;
                handled = true;
              }
          }
          if (handled) event.preventDefault();
        });
      }
    };

    watchEffect(() => {
      // console.log(props.inputElement);
      if (props.inputElement) registerKeyHandlers(props.inputElement);
    });

    return {
      selection,
      isInputFocus,
      completed,
      select
    };
  }
});
