import { Ref } from "@vue/reactivity";

const useDebounce = (callback: (event: any) => void, delay = 500) => {
  let activeTimeout: number | null = null;

  const debounce = (event: any) => {
    if (activeTimeout !== null) {
      clearTimeout(activeTimeout);
    }
    activeTimeout = window.setTimeout(callback.bind(null, event), delay);
  };

  return debounce;
};

export default useDebounce;
