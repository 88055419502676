<template>
  <div
    class="flex flex-wrap justify-center grid-container"
    :class="[
      fullwidth ? '' : 'max-w-body-width mx-auto',
      __elementspacingclass,
      __elementborderclass
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
import { elementSpacingMixin } from "../../mixins/elementSpacing.mixin";
import { elementBorderMixin } from "../../mixins/elementBorder.mixin";

export default {
  name: "flexgrid",
  mixins: [elementSpacingMixin, elementBorderMixin],
  props: {
    fullwidth: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped></style>
