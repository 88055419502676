
import { useFAQManager } from "@/api/faqs/faqManager";
import Page from "@/views/Page.vue";
import { usePageManager } from "@/api/pages/pageManager";

export default {
  name: "FAQs",
  components: {
    Page
  },
  setup() {
    const faqManager = useFAQManager();
    const { loadedPage } = usePageManager();
    const faqCategories: string[] = [];
    const sortedFAQs: Record<string, Record<string, any>[]> = {};
    faqManager.faqs.value.forEach(faq => {
      faq.categories.forEach((category: string) => {
        if (!faqCategories.includes(category)) faqCategories.push(category);
        if (!sortedFAQs[category]) sortedFAQs[category] = [];
        sortedFAQs[category].push(faq);
      });
    });
    // console.log("faqs: ", sortedFAQs);
    // console.log("categories: ", faqCategories);
    return {
      faqs: sortedFAQs,
      selectedFaq: faqManager.selectedFAQ,
      selectFaq: faqManager.selectFAQ,
      faqCategories,
      loadedPage
    };
  }
};
