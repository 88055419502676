import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonControl = _resolveComponent("ButtonControl")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link ? _ctx.link.replace(/^https:\/\/wpdev.netconnect.ch/, '') : '',
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClick()), ["prevent"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ButtonControl, { label: _ctx.label }, null, 8, ["label"])
    ]),
    _: 1
  }, 8, ["to"]))
}