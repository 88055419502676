
import { useRouter } from "vue-router";
import { defineComponent, computed } from "vue";
import Card from "./Card.vue";
export default defineComponent({
  name: "PostPreview",
  components: { Card },
  props: {
    post: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fadingFront: {
      type: Boolean,
      default: false
    },
    fadingBack: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();
    const formattedDate = computed(() => {
      return props.post
        ? new Date(props.post.date).toLocaleString("de-DE")
        : "";
    });
    // console.log("post: ", props.post);
    const onClick = () => {
      if (!props.post) return;
      router.push(
        props.post.link.replace(/^https:\/\/wpcms.peaknetworks.net/, "")
      );
    };
    // console.log(props.post);
    return {
      onClick,
      formattedDate
    };
  }
});
