
import { elementSpacingMixin } from "@/mixins/elementSpacing.mixin";

export default {
  name: "Card",
  mixins: [elementSpacingMixin],
  props: {
    inset: Boolean,
    noPadding: Boolean
  }
};
