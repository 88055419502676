import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Page from "../views/Page.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Page,
  },
  {
    path: "/:path",
    component: Page,
    children: [
      {
        path: ":path",
        component: Page,
        children: [
          {
            path: ":path",
            component: Page,
            children: [
              {
                path: ":path",
                component: Page,
                children: [
                  {
                    path: ":path",
                    component: Page
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
