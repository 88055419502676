<template>
  <div class="max-w-body-width mx-auto my-lg grid grid-cols-1 hd:grid-cols-3 gap-lg">
    <template v-for="(post, i) in posts" :key="post.title.rendered">
      <PostPreview
        class="mb-md hd:mb-0 hd:mr-lg grow-2"
        :fading-back="scrollActive && i === 2"
        :fading-front="scrollActive && i === 0"
        :post="post"
      />
    </template>
  </div>
</template>

<script>
import PostPreview from "./PostPreview";
import { usePostManager } from "../../api/posts/postManager";
import { computed } from "vue";

export default {
  name: "PostPreviewOutlet",
  components: {
    PostPreview
  },
  props: {
    visible_posts: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    // console.log(props);
    const { posts } = usePostManager();
    const scrollActive = computed(() => {
      return posts.length > 3;
    });
    if(props.visible_posts > 0 )
        posts.value = posts.value.slice(0, props.visible_posts);
    // console.log(posts);
    return {
      posts,
      scrollActive
    };
  }
};
</script>

<style>
</style>
