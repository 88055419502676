import { ref, computed } from 'vue';
import axios, {AxiosResponse} from "axios";
import {usePageManager} from "@/api/pages/pageManager";

const posts = ref<Record<string, any>[]>([]);
const pageManager = usePageManager();

// const getPosts = (): Promise<AxiosResponse | void> => {
//     return new Promise((resolve, reject) => {
//         axios.get('https://wpdev.netconnect.ch/wp-json/wp/v2/posts', {
//             params: {
//                 // eslint-disable-next-line @typescript-eslint/camelcase
//                 per_page: 100
//             }
//         }).then((response) => {
//             posts.value = response.data;
//             pageManager.addPages(posts.value);
//             resolve();
//         }).catch((error) => {
//             console.warn(error);
//         });
//     })
// }

const setPosts = (postList: never[]): void => {
    posts.value = postList;
    pageManager.addPages(postList);
}

const setPostsFromPages = (pageList: never[]): void => {
    pageList.forEach((page: Record<string, any>) => {
        if(page.type === 'post') posts.value.push(page)
    })
}

export const usePostManager = () => ({
    setPostsFromPages,
    setPosts,
    posts
})
