
import Card from "./Card.vue";
import ButtonControl from "@/components/internal/ButtonControl.vue";
import Datepicker from "vue3-datepicker";
import { useProductsManager } from "@/api/products/productsManager";
import { Product } from "@/types/wp-types";
import { PaymentInterval, ProductCategories } from "@/types/wp-enums";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useLocationManager } from "@/api/location/locationManager";
import { useVuelidate } from "@vuelidate/core";
import { email, required, requiredIf, sameAs } from "@vuelidate/validators";
import InputControl from "@/components/internal/InputControl.vue";
import { ShopForm } from "@/types/shopForm";
import { useMailer } from "@/api/mails/mail";
import { useRouter } from "vue-router";
import { useResponsive } from "@/api/responsive/responsive";
import LocationCheck from "@/components/wp-widgets/LocationCheck.vue";

export default {
  name: "Shop",
  components: {
    LocationCheck,
    ButtonControl,
    InputControl,
    Card,
    Datepicker
  },
  props: {
    maincategory: {
      type: String,
      default: "internet"
    }
  },
  setup(props: Record<string, any>) {
    const {
      zip,
      city,
      street,
      houseNr,
      connectionType,
      shopMode,
      maxDown
    } = useLocationManager();
    const {
      products,
      installationCosts,
      chosenProduct,
      paymentIntervalStrings
    } = useProductsManager();
    const { isMobile } = useResponsive();
    const { getNonceAndSendMail } = useMailer();
    const categorizedProducts: Record<string, Product[]> = {};
    const router = useRouter();
    const loading = ref(false);
    const form = reactive<ShopForm>({
      selectedMainProduct: chosenProduct.value || null,
      selected: {},
      installation: "standard",
      installationAddress: "identical",
      deliveryAddress: "identical",
      contractTime: "24",
      customInstallationDate: new Date(),
      message: "",
      salutation: "",
      company: "",
      name: "",
      surname: "",
      phone: "",
      email: "",
      receiptInterval: "monthly",
      receiptType: "invoice",
      alternateStreet: "",
      alternateHouseNr: "",
      alternatePlz: "",
      alternateTown: "",
      loanedDevice: true,
      iban: "",
      nsn: "",
      agb: false,
      otoId: "",
      previousTenant: ""
    });
    // console.log(form);
    const validationRules = computed(() => ({
      selectedMainProduct: { required },
      salutation: { required },
      company: requiredIf(shopMode.value === "business"),
      name: { required },
      surname: { required },
      phone: { required },
      email: { required, email },
      agb: { required, checked: sameAs(true)  },
      alternateStreet: {
        required: requiredIf(form.deliveryAddress === "other")
      },
      alternateHouseNr: {
        required: requiredIf(form.deliveryAddress === "other")
      },
      alternatePlz: { required: requiredIf(form.deliveryAddress === "other") },
      alternateTown: { required: requiredIf(form.deliveryAddress === "other") },
      iban: { required: requiredIf(form.receiptType === "lsv") }
    }));

    const showSummary = ref(false);

    const v$ = useVuelidate(validationRules, form);

    const currentStep = ref<number>(1);
    const oldStep     = ref<number>(5);
    const stepInvalid = computed(() => {
      if (currentStep.value === 1) {
        return (
          v$.value.name.$invalid ||
          v$.value.surname.$invalid ||
          v$.value.phone.$invalid ||
          v$.value.email.$invalid
        );
      }
      if (currentStep.value === 2) {
        return (
          v$.value.alternateTown.$invalid ||
          v$.value.alternatePlz.$invalid ||
          v$.value.alternateStreet.$invalid ||
          v$.value.alternateHouseNr.$invalid ||
          v$.value.iban.$invalid
        );
      }
      return false;
    });

    form.customInstallationDate.setDate(new Date().getDate() + 7);

    const minInstallationDate = ref<Date>(
      new Date(form.customInstallationDate)
    );

    const selectedPrices = computed(() => {
      const prices = {
        monthly: 0.0,
        once: 0.0
      };
      if (form.selectedMainProduct && form.selectedMainProduct.price) {
        if (
          form.selectedMainProduct.payment_interval == PaymentInterval.MONTHLY
        ) {
          prices.monthly += parseFloat(form.selectedMainProduct.price);
        } else if (
          form.selectedMainProduct.payment_interval == PaymentInterval.ONCE
        ) {
          prices.once += parseFloat(form.selectedMainProduct.price);
        }
      }
      Object.keys(form.selected).forEach(selectedProduct => {
        if (
          form.selected[selectedProduct] &&
          form.selected[selectedProduct].price
        ) {
          if (
            form.selected[selectedProduct].payment_interval ==
            PaymentInterval.MONTHLY
          ) {
            prices.monthly += parseFloat(form.selected[selectedProduct].price);
          } else if (
            form.selected[selectedProduct].payment_interval ==
            PaymentInterval.ONCE
          ) {
            prices.once += parseFloat(form.selected[selectedProduct].price);
          }
        }
      });
      if( parseInt(form.contractTime) < 36 )
      {
        prices.once += parseFloat(installationCosts.value[form.contractTime]);
      }
      return prices;
    });

    const availableMainProducts = computed(() => {
      const prod = categorizedProducts[props.maincategory].filter(
        product => product.available
      );
      return prod.sort(
        (a, b) => parseInt(a.down_mbits) - parseInt(b.down_mbits)
      );
    });

    const availableRouters = computed(() => {
      return categorizedProducts["router"].filter(router => {
        return (
          (router.connection_type === connectionType.value ||
            router.connection_type === "both") &&
          parseFloat(router.down_mbits) >=
            parseFloat(
              form.selectedMainProduct
                ? form.selectedMainProduct.down_mbits
                : "0"
            )
        );
      });
    });
    const loanableRouter = computed(() => {
      return availableRouters.value.find(
        router => router.contract_type === shopMode.value && router.loanable
      );
    });

    const categorizeProduct = (product: Product) => {
      if (!product.product_category) return;
      const category = product.product_category;
      if (!categorizedProducts[category]) {
        categorizedProducts[category] = [];
      }
      if (
        !categorizedProducts[category].find(
          categorizedProduct => categorizedProduct.id === product.id
        )
      ) {
        categorizedProducts[category].push(product);
      }
    };

    const nextStep = () => {
      const GTMEventString = `step-${currentStep.value}`;
      window.dataLayer.push({ event: GTMEventString });
      currentStep.value += 1;
    };
    const previousStep = () => {
      if (currentStep.value > 1) currentStep.value -= 1;
    };
    const configure = () => {
       oldStep.value = currentStep.value;
       currentStep.value = 5;
    };
    const goBack = () => {
       currentStep.value = oldStep.value;
    };
    const order = () => {
      // console.log("ordering");
      loading.value = true;
      const mailContent = `\n
        Name: ${form.salutation} ${form.name} ${form.surname}\n
        Adresse: ${street.value} ${houseNr.value},  ${zip.value} ${city.value}\n
        Telefon: ${form.phone}\n
        Email: ${form.email}\n
        Internetprodukt: ${
          form.selectedMainProduct ? form.selectedMainProduct.name : ""
        }\n
        IP Adressen: ${
          form.selected.ip.name ? form.selected.ip.name : "1x dynamisch"
        }\n
        Router: ${
          form.loanedDevice && loanableRouter.value
            ? "LEIHGERÄT: " + loanableRouter.value.name
            : form.selected.router.name
        }\n
        Bridge: ${
          form.selected.bridge.name ? form.selected.bridge.name : "keine"
        }\n
        ${connectionType.value === "fiber" ? "OTO ID: " : "NSN Nummer: "}${
        connectionType.value === "fiber"
          ? form.otoId || "keine Angabe"
          : form.nsn || "keine Angabe"
        }\n
        ${
          connectionType.value === "dsl"
            ? "Vormieter: " + form.previousTenant || "keine Angabe\n"
            : ""
        }
        Laufzeit: ${form.contractTime} Monate\n
        Aufschaltungstermin: ${
          !form.customInstallationDate
            ? form.installation
            : form.customInstallationDate.toDateString()
        }
        Zahlungsintervall: ${form.receiptInterval}\n
        Zahlungsart: ${form.receiptType}\n
        ${form.receiptType === "lsv" ? "IBAN: " + form.iban + "\n" : ""}
        Lieferadresse: ${
          form.deliveryAddress === "identical"
            ? "identisch mit Aufschaltadresse"
            : form.alternateStreet +
              " " +
              form.alternateHouseNr +
              ", " +
              form.alternatePlz +
              " " +
              form.alternateTown
        }\n
        Preis: ${selectedPrices.value.monthly.toFixed(
          2
        )} monatlich, ${selectedPrices.value.once.toFixed(2)} einmalig\n
        Bemerkungen: ${form.message}
      `;
      // console.log(mailContent);
      getNonceAndSendMail({
        email: form.email,
        name: form.name,
        surname: form.surname,
        recipient: "",
        customerNumber: "",
        companyName: "",
        subject: `Bestellung: ${
          form.selectedMainProduct ? form.selectedMainProduct.name || "" : ""
        }`,
        message: mailContent
      }).then(result => {
        window.dataLayer.push({ event: "order-complete" });
        window.setTimeout(() => {
          router.push("danke");
        }, 1000);
      });
    };

    products.value.forEach(product => {
      categorizeProduct(product);
      if (product.optional_products) {
        product.optional_products.forEach(oProduct => {
          categorizeProduct(oProduct);
        });
      }
    });
    // console.log(categorizedProducts);

    const subCategories = Object.keys(categorizedProducts).filter(
      category => category !== props.maincategory
    );

    subCategories.forEach(category => {
      if (!form.selected[category]) form.selected[category] = {} as Product;
    });

    onMounted(() => {
      if (!loanableRouter.value) {
        form.loanedDevice = false;
      }
      // console.log("available routers: ", availableRouters.value);
    });

    watch(
      () => form.selected.router,
      (router, prevRouter) => {
        if (Object.keys(router).length > 0) {
          form.loanedDevice = false;
        }
      }
    );
    watch(
      () => form.loanedDevice,
      (loaned, prevLoaned) => {
        // console.log("loaned watcher: ", loaned);
        if (loaned) {
          // console.log("deselect selected router");
          form.selected.router = {} as Product;
          // console.log(form.selected.router);
        }
      }
    );

    // console.log(subCategories, selected);

    // console.log(categorizedProducts);
    return {
      ProductCategories,
      categorizedProducts,
      subCategories,
      selectedPrices,
      console,
      zip,
      city,
      street,
      houseNr,
      nextStep,
      previousStep,
      order,
      configure,
      goBack,
      minInstallationDate,
      installationCosts,
      connectionType,
      paymentIntervalStrings,
      shopMode,
      availableRouters,
      loanableRouter,
      availableMainProducts,
      form,
      currentStep,
      v$,
      stepInvalid,
      isMobile,
      loading,
      showSummary
    };
  }
};
