
import Card from "./Card.vue";
import { ref, computed } from "vue";
import { useProductsManager } from "@/api/products/productsManager";
import { elementSpacingMixin } from "@/mixins/elementSpacing.mixin";
import { elementColorsMixin } from "@/mixins/elementColors.mixin";
import { useLocationManager } from "@/api/location/locationManager";
import router from "@/router";
import ButtonControl from "@/components/internal/ButtonControl.vue";

export default {
  name: "ProductCard",
  components: {
    Card,
    ButtonControl
  },
  mixins: [elementSpacingMixin, elementColorsMixin],
  props: {
    productid: Number,
    hideprice: {
      type: Boolean,
      default: false
    },
    infobutton: {
      type: Boolean,
      default: false
    },
    orderbutton: String,
    orderbuttonlabel: String,
    imagesize: String
  },
  setup(props: Record<string, any>) {
    const productsManager = useProductsManager();
    const locationManager = useLocationManager();
    // console.log("product card: ", props, props.__elementspacingclass);
    const loadedProduct = productsManager.getProductById(
      parseInt(props.productid)
    );
    const invertedSpacings: string[] = [];
    props.__elementspacingclass.split(" ").forEach((className: string) => {
      if (
        className.includes("pt") ||
        className.includes("pr") ||
        className.includes("pl")
      ) {
        invertedSpacings.push(className.replace("p", "-m"));
      }
    });
    const available = computed(
      () => loadedProduct?.available && locationManager.checkComplete.value
    );
    const recommended = computed(
      () => loadedProduct?.recommended && locationManager.checkComplete.value
    );
    const scrollToChecker = () => {
      const checker = document.querySelector("#location-check");
      if (checker) {
        checker.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
    const onProductSelected = () => {
      if (!locationManager.checkComplete.value) {
        scrollToChecker();
      } else {
        const GTMEventString = `click-bestellung`;
        window.dataLayer.push({ event: GTMEventString });
        productsManager.setChosenProduct(loadedProduct);
        router.push("/bestellen");
      }
    };
    // console.log("loaded product: ", loadedProduct);
    let splitPrice: string[] = ["00", "00"];
    let paymentIntervalString = "";
    if (loadedProduct) {
      splitPrice = loadedProduct.price.split(".");
      paymentIntervalString =
        productsManager.paymentIntervalStrings.value[
          loadedProduct.payment_interval
        ];
    }
    return {
      loadedProduct,
      splitPrice,
      paymentIntervalString,
      invertedSpacings,
      checkComplete: locationManager.checkComplete,
      available,
      recommended,
      onProductSelected,
      scrollToChecker,
      loading: locationManager.loading
    };
  }
};
