
import { elementSpacingMixin } from "../../mixins/elementSpacing.mixin";
import { elementSizeMixin } from "../../mixins/elementSize.mixin";
import { defineComponent } from "vue";
import Card from "./Card.vue";

export default defineComponent({
  name: "ImageLink",
  mixins: [elementSpacingMixin, elementSizeMixin],
  components: { Card },
  props: {
    heading: {
      type: String,
      default: ""
    },
    linklabel: {
      type: String,
      default: "Link"
    },
    link: {
      type: String,
      default: ""
    },
    imageurl: {
      type: String,
      default: ""
    }
    // elementspacingclass: String
  }
});
