<template>
  <div
    ref="heroElement"
    class="flex flex-col bg-no-repeat bg-hero"
    :class="{
      'h-hero': !isMobile,
      'h-hero-mobile': isMobile,
      'flex-full': fullheight,
      'max-w-body-width w-body-width mx-auto': !fullwidth,
      'w-full bg-cover': fullwidth
    }"
    :style="{
      'background-image': `url('${backgroundimage}')`,
      'background-color': overlaycolor,
      'background-blend-mode': 'overlay',
      'background-position-x':
        isSmaller && backgroundxmobile
          ? backgroundxmobile
          : isHD
          ? backgroundxhd
          : backgroundx,
      'background-position-y':
        isSmaller && backgroundymobile
          ? backgroundymobile
          : isHD
          ? backgroundyhd
          : backgroundy,
      'background-size': isSmaller
        ? backgroundsizemobile
        : isHD
        ? backgroundsizehd
        : backgroundsize
    }"
  >
    <div
      class="hero-content flex flex-col h-full max-w-body-width w-body-width mx-auto"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { TitleState, usePageManager } from "../../api/pages/pageManager";
import { onBeforeUnmount, ref } from "vue";
import { useResponsive } from "../../api/responsive/responsive";

export default {
  name: "Hero",
  props: {
    backgroundimage: {
      type: String,
      default: ""
    },
    overlaycolor: {
      type: String,
      default: () => "rgba(0, 0, 0, 0)"
    },
    fullheight: {
      type: Boolean,
      default: false
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    backgroundx: {
      type: String,
      default: "center"
    },
    backgroundy: {
      type: String,
      default: "center"
    },
    backgroundxmobile: {
      type: String,
      default: "center"
    },
    backgroundymobile: {
      type: String,
      default: "center"
    },
    backgroundxhd: {
      type: String,
      default: "center"
    },
    backgroundyhd: {
      type: String,
      default: "center"
    },
    backgroundsize: {
      type: String,
      default: "auto"
    },
    backgroundsizemobile: {
      type: String,
      default: "contain"
    },
    backgroundsizehd: {
      type: String,
      default: "contain"
    }
  },
  setup(props, context) {
    // console.log("hero props: ", props);
    const pageManager = usePageManager();
    const { isMobile, isSmaller, isHD } = useResponsive();
    const pageTitle = pageManager.loadedPage.value.title.rendered;
    const heroElement = ref(null);
    const bottomInset = ref(null);
    const hasInsetContent = !!context.slots["bottom-inset"];

    // const handleInsetPlacement = () => {
    //   setTimeout(() => {
    //     const maxInset = heroElement.value.clientHeight / 4;
    //     const inset = bottomInset.value.clientHeight * 2;
    //     heroElement.value.style.marginBottom =
    //       inset < maxInset ? -inset + "px" : -maxInset + "px";
    //     console.log(heroElement.value.style.marginBottom);
    //   });
    // };
    //
    // onMounted(() => {
    //   if (!props.fullheight) handleInsetPlacement();
    // });
    console.log(props);
    pageManager.setTitleState(TitleState.HIDDEN);
    onBeforeUnmount(() => {
      pageManager.setTitleState(TitleState.VISIBLE);
    });

    return {
      heroElement,
      bottomInset,
      pageTitle,
      hasInsetContent,
      isMobile,
      isHD,
      isSmaller
    };
  }
};
</script>

<style scoped>
.h-hero-mobile {
  height: calc(100vh - theme("spacing.nav-small"));
}
</style>
