<template>
  <div
    class="grid"
    :class="[
      fullwidth ? '' : 'max-w-body-width w-body-width mx-auto',
      !dynamic ? `grid-cols-${cols}` : '',
      `gap-x-${gapx}`,
      `gap-y-${gapy}`,
      `${centervertical ? 'items-center' : ''}`,
      dynamic
        ? `dynamic-${cols}${allowchildwrap ? '' : '-max'} justify-center`
        : '',
      __elementspacingclass,
      __elementborderclass
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
import { elementSpacingMixin } from "../../mixins/elementSpacing.mixin";
import { elementBorderMixin } from "../../mixins/elementBorder.mixin";

export default {
  name: "Grid",
  mixins: [elementSpacingMixin, elementBorderMixin],
  props: {
    fullwidth: {
      type: String,
      default: null
    },
    cols: {
      type: String,
      default: "2"
    },
    gapx: {
      type: String,
      default: "lg"
    },
    gapy: {
      type: String,
      default: "lg"
    },
    dynamic: {
      type: Boolean,
      default: true
    },
    allowchildwrap: {
      type: String,
      default: null
    },
    centervertical: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped></style>
