import axios from "axios";
import {env} from "@/api/env";
import {ref, computed} from "vue";

const { baseUrl } = env()

const contactFormCategories = ref<string[]>([]);
const fixedCategory = ref<string>("");
const fixedSubject = ref<string>("");

const getNonce = () => {
    return axios.get(`${baseUrl.value}/wp-json/peak/v1/mail/nonce`);
}

const sendMail = (mail: MailData) => {
    return axios.post(`${baseUrl.value}/wp-json/peak/v1/mail`, mail);
}

const getNonceAndSendMail = (mailForm: MailFormData, processMessage = true) => {
    return new Promise((resolve, reject) => {
        // window.setTimeout(() => { resolve(true) }, 1000);
        getNonce().then((response) => {
            // console.log('nonce response: ', response.data);
            const processedMessage = mailForm.message + `\n\n        Sender name: ${mailForm.name} ${mailForm.surname}\n        Sender email: ${mailForm.email}\n        Kundennummer: ${mailForm.customerNumber}\n        Firmenname: ${mailForm.companyName}`
            const mail: MailData = {
                email: mailForm.email,
                message: processMessage ? processedMessage : mailForm.message,
                nonce: response.data.nonce,
                recipient: "",
                subject: mailForm.subject
            };
            sendMail(mail).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject('Error occured while sending the mail');
            })
        }).catch((error) => {
            reject('Error occured while sending the mail');
        })
    })
}

const setFormCategory = (category: string) => {
    fixedCategory.value = category;
}
const unsetFormCategory = () => {
    fixedCategory.value = "";
}
const setFormSubject = (subject: string) => {
    fixedSubject.value = subject;
}
const unsetFormSubject = () => {
    fixedSubject.value = "";
}
const setContactFormCategories = (categories: Record<string, any>[]) => {
    const cats: string[] = [];
    categories.forEach(cat => {
        cats.push(cat.acf.category_name)
    })
    contactFormCategories.value = cats;
    // console.log('categories: ', cats);
}

export const useMailer = () => ({
    getNonceAndSendMail,
    setFormCategory,
    unsetFormCategory,
    setContactFormCategories,
    setFormSubject,
    unsetFormSubject,
    contactFormCategories: computed(() => contactFormCategories.value),
    fixedCategory: computed(() => fixedCategory.value),
    fixedSubject: computed(() => fixedSubject.value)
})

export interface MailData {
    recipient: string;
    message: string;
    nonce: string;
    email: string;
    subject: string;
}

export interface MailFormData {
    name: string;
    surname: string;
    recipient: string;
    message: string;
    email: string;
    subject: string;
    customerNumber: string;
    companyName: string;
}
