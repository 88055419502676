
import { defineComponent, ref } from "vue";
const Component = defineComponent({
  name: "Test",
  props: {
    msg: String
  },
  setup(props) {
    const message = ref(props.msg);
    // console.log(props);
    return {
      message
    };
  }
});

export default Component;
