
import { elementSpacingMixin } from "@/mixins/elementSpacing.mixin";

export default {
  name: "Citation",
  mixins: [elementSpacingMixin],
  props: {
    text: {
      type: String,
      default: "Citation"
    },
    person: {
      type: String,
      default: "Person"
    },
    media: {
      type: String,
      default: ""
    }
  }
};
