import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29f64e91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-body-width mx-auto" }
const _hoisted_2 = { class: "text-center my-xl" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 0,
  class: "has-larger-font-size font-bold"
}
const _hoisted_6 = {
  key: 1,
  class: "has-larger-font-size font-bold text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.faqCategories, (category) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `faq-category-${category}`
      }, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(category), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.faqs[category], (faq, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${category}-faq-${i}`,
            class: "faq-entry border-standard border-border py-md px-lg mb-lg",
            onClick: ($event: any) => ($setup.selectFaq($setup.selectedFaq === faq ? null : faq.id))
          }, [
            _createVNode(_component_router_link, {
              to: { path: '', hash: `#${faq.slug}` },
              class: "flex my-md justify-between cursor-pointer group"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", {
                  id: faq.slug,
                  class: "ml-0"
                }, _toDisplayString(faq.title.rendered), 9, _hoisted_4),
                (faq !== $setup.selectedFaq)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, " + "))
                  : (_openBlock(), _createElementBlock("p", _hoisted_6, "-"))
              ]),
              _: 2
            }, 1032, ["to"]),
            _createVNode(_component_Page, {
              content: faq.content.rendered,
              class: _normalizeClass(["faq-content-container flex-0", {
            'max-h-half-screen': $setup.selectedFaq === faq,
            'max-h-0': $setup.selectFaq !== faq
          }]),
              backgroundColor: "bg-white"
            }, null, 8, ["content", "class"])
          ], 8, _hoisted_3))
        }), 128))
      ], 64))
    }), 128))
  ]))
}