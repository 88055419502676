import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1edaf691"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = {
  key: 0,
  class: "text-white uppercase mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cube_shadow = _resolveComponent("cube-shadow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["py-md px-xl m-auto label", {
        'bg-error': _ctx.status === 'Offline',
        'bg-warning': _ctx.status === 'Limited',
        'bg-success': _ctx.status === 'Operational'
      }])
    }, [
      (!_ctx.statusLoading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.status), 1))
        : (_openBlock(), _createBlock(_component_cube_shadow, {
            key: 1,
            class: "m-auto",
            background: "#E73E07",
            size: "30px"
          }))
    ], 2)
  ]))
}