import { ref, computed } from 'vue';
import axios, {AxiosResponse} from "axios";
import { Product, WPOptionalProductResponse, WPProductResponse} from "@/types/wp-types";

const products = ref<Product[]>([]);
const paymentIntervalStrings = [
    " einmalig",
    " / Monat",
    " / Jahr"
];
const installationCosts = ref<Record<string, string>>({
    '24': '49.00',
    '36': '0.00',
});

const chosenProduct = ref<Product>();
// const optionalProducts = ref<OptionalProduct[]>([]);

const getProducts = (): Promise<AxiosResponse | void> => {
    return new Promise((resolve, reject) => {
        axios.get<WPProductResponse>('https://wpdev.netconnect.ch/wp-json/acf/v3/products').then((productResponse) => {
            axios.get<WPOptionalProductResponse>('https://wpdev.netconnect.ch/wp-json/acf/v3/optional_products').then((oProductResponse) => {
                productResponse.data.forEach((product) => {
                    product.acf.id = product.id;
                    const resolvedOptionalProducts: Product[] = [];
                    if(product.acf.optional_products) {
                        product.acf.optional_products.forEach((oProductId) => {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            const oProduct = oProductResponse.data.find((op) => op.id === oProductId) || {id: null,
                                acf: {
                                    name: 'Product not found',
                                    id: -1,
                                    price: "00.00",
                                    currency: "CHF",
                                    // eslint-disable-next-line @typescript-eslint/camelcase
                                    payment_interval: 0
                                }
                            };
                            if (oProduct) {
                                oProduct.acf.id = oProductId;
                            }
                            resolvedOptionalProducts.push(oProduct.acf);
                        });
                    }
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    const resolvedProduct: Product = { ...product.acf, optional_products: resolvedOptionalProducts }
                    products.value.push(resolvedProduct);
                });
                resolve();
            }).catch((error) => {
                console.warn(error);
            })
        }).catch((error) => {
            console.warn(error);
        })
    });
}

const getProductById = (productId: number): Product | null => {
    return products.value.find((product) => product.id === productId) || null;
}

// const getOptionalProducts = (): Promise<AxiosResponse | void> => {
//     return axios.get('https://wpdev.netconnect.ch/wp-json/acf/v3/optional_products').then((response) => {
//         console.log(response);
//         optionalProducts.value = response.data;
//         response.data.forEach((oProduct) => {
//
//         })
//     })
// }

const setProducts = (productList: never[]): void => {
    products.value = productList;
}
const setChosenProduct = (product: any): void => {
    chosenProduct.value = product;
}
const unsetChosenProduct = (): void => {
    chosenProduct.value = undefined
}

export const useProductsManager = () => ({
    getProducts,
    getProductById,
    // getOptionalProducts,
    setProducts,
    setChosenProduct,
    unsetChosenProduct,
    products,
    installationCosts,
    chosenProduct: computed(() => chosenProduct.value),
    paymentIntervalStrings: computed(() => paymentIntervalStrings)
    // optionalProducts
})
