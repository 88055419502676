import { ref, computed } from "vue";
import axios, { AxiosResponse } from "axios";
import { env } from "@/api/env";
import { useProductsManager } from "@/api/products/productsManager";
import router from "@/router";

export enum LocationOrigins {
  ZIPCODE = "zipcode",
  TOWNS = "gg25",
  DISTRICT = "district",
  CANTON = "kantone",
  GAZETTEER = "gazetteer",
  ADDRESS = "address",
  PARCEL = "parcel"
}

export interface LocationData {
  zip: string;
  city: string;
  street: string;
  houseNr: string;
}

const geoAdminSearchPath =
  "https://api3.geo.admin.ch/rest/services/api/SearchServer";
const scUserId = "100024";
const { baseUrl } = env();

const zipCodeResults = ref<Record<string, any>[]>([]);
const townResults = ref<Record<string, any>[]>([]);
const addressResults = ref<Record<string, any>[]>([]);

const zip = ref("");
const city = ref("");
const street = ref("");
const houseNr = ref("");

const shopMode = ref<"private" | "business">("private");

const products = useProductsManager().products;
const loading = ref<boolean>(false);
const checkComplete = ref<boolean>(false);
const evaluationResult = ref<Record<string, any>>({
  resultCode: null,
  potential: null,
  resultState: "",
  planned: false,
  sockets: [],
  maxAccessSpeed: {
    up: 0,
    down: 0,
    bbType: null
  },
  effectiveMaxSpeed: {
    up: 0,
    down: 0,
    bbType: null
  },
  currentSpeed: {
    up: 0,
    down: 0,
    bbType: null
  },
  cpeName: "",
  result: "",
  type: "",
  q31: false
});

const getMaxEffectiveSpeed = (qualifAnswer: Record<string, any>) => {
  qualifAnswer.qualifProfile.forEach((profile: any) => {
    const effectiveDown = profile.effectiveSpeed.down;
    const effectiveUp = profile.effectiveSpeed.up;
    if (effectiveDown > evaluationResult.value.effectiveMaxSpeed.down) {
      evaluationResult.value.effectiveMaxSpeed.down = effectiveDown;
      evaluationResult.value.effectiveMaxSpeed.up = effectiveUp;
    }
  });
};

const getMaxAccessSpeed = (qualifAnswer: Record<string, any>) => {
  const maxSpeed = qualifAnswer.maxAccessSpeed.max;
  if (maxSpeed.down > evaluationResult.value.maxAccessSpeed.down) {
    evaluationResult.value.maxAccessSpeed.down = maxSpeed.down;
    evaluationResult.value.maxAccessSpeed.up = maxSpeed.up;
  }
};

const evaluateDSLResult = (response: any) => {
  const results = Array.isArray(response.qualificationResult)
    ? response.qualificationResult
    : [response.qualificationResult];
  results.forEach((result: any) => {
    const qualifAnswer = Array.isArray(result.qualifAnswer)
      ? result.qualifAnswer
      : [result.qualifAnswer];
    // console.log(result, qualifAnswer)
    qualifAnswer.forEach((answer: any) => {
      switch (answer.qualiResultState) {
        case "ok":
        case "ok_stao":
        evaluationResult.value.type = "dsl";
          evaluationResult.value.resultState = answer.qualiResultState;
          evaluationResult.value.resultCode =
            answer.qualiResultDetail.resultCode;
          if (answer.potentialAvailable) {
            evaluationResult.value.potential = answer.potentialAvailable;
            getMaxEffectiveSpeed(answer);
            evaluationResult.value.effectiveMaxSpeed.bbType = result.bbType;
          }
          if (answer.maxAccessSpeed.max) {
            getMaxAccessSpeed(answer);
            evaluationResult.value.maxAccessSpeed.bbType = qualifAnswer.bbType;
          }
          break;
        case "planned":
          evaluationResult.value.planned = true;
          break;
        case "nok":
          if (answer.qualiResultDetail.resultCode === "Q29") {
            if (answer.potentialAvailable) {
              evaluationResult.value.potential = answer.potentialAvailable;
              getMaxEffectiveSpeed(answer);
              evaluationResult.value.effectiveMaxSpeed.bbType = result.bbType;
            }
          }
          if (answer.maxAccessSpeed && answer.maxAccessSpeed.max) {
            getMaxAccessSpeed(answer);
            evaluationResult.value.maxAccessSpeed.bbType = qualifAnswer.bbType;
            evaluationResult.value.resultState = "ok_migrationonly";
        evaluationResult.value.type = "dsl";
          }
          break;
      }
      if (answer.cpeInfo && answer.cpeInfo.cpeName) {
        evaluationResult.value.cpeName = answer.cpeInfo.cpeName;
      }
      if (answer.currentAccessSpeed) {
        evaluationResult.value.currentSpeed.up =
          answer.currentAccessSpeed.max.up;
        evaluationResult.value.currentSpeed.down =
          answer.currentAccessSpeed.max.down;
        evaluationResult.value.currentSpeed.bbType =
          answer.currentAccessSpeed.technologyType;
      }
    });
  });
  evaluationResult.value.result =
    evaluationResult.value.maxAccessSpeed.up > 0 &&
    evaluationResult.value.maxAccessSpeed.down > 0
      ? "ok"
      : "unavailable";
};

const evaluateFiberResult = (response: any) => {
  const results = Array.isArray(response.qualificationResult)
    ? response.qualificationResult
    : [response.qualificationResult];
  results.forEach((result: any) => {
    if (!result.fiberQualifAnswer) return;
    const qualifAnswer = Array.isArray(result.fiberQualifAnswer)
      ? result.fiberQualifAnswer
      : [result.fiberQualifAnswer];
    qualifAnswer.forEach((answer: any) => {
      if (answer.socket) {
        const sockets = Array.isArray(answer.socket)
          ? answer.socket
          : [answer.socket];
        sockets.forEach((soc: any) => {
          if (soc.socketId) evaluationResult.value.sockets.push(soc.socketId);
        });
      }
      switch (answer.qualiResultState) {
        case "ok":
        case "ok_stao":
          if (answer.bep.maxAccessSpeedKbps) {
            const speed = answer.bep.maxAccessSpeedKbps;
            if (speed > evaluationResult.value.maxAccessSpeed.down) {
              evaluationResult.value.maxAccessSpeed.down = speed;
              evaluationResult.value.maxAccessSpeed.up = speed;
              evaluationResult.value.result = "ok";
              evaluationResult.value.type = "fiber";
            }
          } else {
            evaluationResult.value.result = "unavailable";
          }
          break;
        case "nok":
          break;
        case "planned":
          evaluationResult.value.planned = true;
          break;
      }
    });
  });
};

const matchProducts = () => {
  const internetProducts = products.value.filter(product => {
    return !!product.down_mbits;
  });
  internetProducts.sort(
    (a, b) => parseInt(a.down_mbits) - parseInt(b.down_mbits)
  );
  // console.log(internetProducts)
  internetProducts.every((product, index) => {
    // console.log(evaluationResult.value.maxAccessSpeed.down / 1000, parseInt(product.down_mbits), evaluationResult.value.maxAccessSpeed.down / 1000 >= parseInt(product.down_mbits))
    if (
      evaluationResult.value.maxAccessSpeed.down / 1000 >=
      parseInt(product.down_mbits)
    ) {
      product.available = true;
      product.availableAs = evaluationResult.value.type;
      if (
        evaluationResult.value.maxAccessSpeed.down / 1000 ===
          parseInt(product.down_mbits) ||
        index === internetProducts.length - 1
      ) {
        // console.log('this should be recommended', product)
        product.recommended = true;
      }
    } else {
      // console.log('this should be recommended', product)
      product.available = true;
      product.availableAs = evaluationResult.value.type;
      product.recommended = true;
    }
    return !product.recommended;
  });
  // console.log(products)
};

const resetData = () => {
  evaluationResult.value = {
    resultCode: null,
    potential: null,
    resultState: "",
    planned: false,
    sockets: [],
    maxAccessSpeed: {
      up: 0,
      down: 0,
      bbType: null
    },
    effectiveMaxSpeed: {
      up: 0,
      down: 0,
      bbType: null
    },
    currentSpeed: {
      up: 0,
      down: 0,
      bbType: null
    },
    cpeName: "",
    result: "",
    type: "",
    q31: false
  };
  zip.value = "";
  city.value = "";
  street.value = "";
  houseNr.value = "";
  checkComplete.value = false;
  products.value.forEach(product => {
    product.recommended = false;
    product.available = false;
    product.availableAs = "";
  });
  zipCodeResults.value = [];
  townResults.value = [];
  addressResults.value = [];
};

const requestTownAutocomplete = (input: string, origins?: LocationOrigins) => {
  return new Promise((resolve, reject) => {
    if (input === "") {
      switch (origins) {
        case LocationOrigins.ZIPCODE:
          zipCodeResults.value = [];
          break;
        case LocationOrigins.TOWNS:
          townResults.value = [];
          break;
        case LocationOrigins.ADDRESS:
          addressResults.value = [];
          break;
        default:
          break;
      }
      return;
    }
    axios
      .get(geoAdminSearchPath, {
        params: {
          searchText: input,
          type: "locations",
          origins: origins ? origins : null
        }
      })
      .then(response => {
        // console.log(response);
        switch (origins) {
          case LocationOrigins.ZIPCODE:
            zipCodeResults.value = response.data.results.map(
              (item: Record<string, any>) =>
                item.attrs.label.replace(/<\/?b>/g, "")
            );
            break;
          case LocationOrigins.TOWNS:
            townResults.value = response.data.results.map(
              (item: Record<string, any>) =>
                item.attrs.label.replace(/<\/?b>/g, "")
            );
            break;
          case LocationOrigins.ADDRESS:
            addressResults.value = response.data.results.map(
              (item: Record<string, any>) =>
                item.attrs.label.replace(/<\/?b>/g, "")
            );
            break;
          default:
            break;
        }
        resolve(response);
      })
      .catch(error => {
        switch (origins) {
          case LocationOrigins.ZIPCODE:
            zipCodeResults.value = [];
            break;
          case LocationOrigins.TOWNS:
            townResults.value = [];
            break;
          case LocationOrigins.ADDRESS:
            addressResults.value = [];
            break;
          default:
            break;
        }
        reject(error);
      });
  });
};

const checkLocation = (locationData: LocationData): Promise<any> => {
  loading.value = true;
  zip.value = locationData.zip;
  city.value = locationData.city;
  street.value = locationData.street;
  houseNr.value = locationData.houseNr;
  return axios
    .post(`${baseUrl.value}/wp-json/swisscom/v1/availability`, {
      ...locationData
    })
    .then(response => {
      // console.log('availability response: ', response);

      if (response.data.dsl.success) {
        evaluateDSLResult(response.data.dsl);
      }
      console.log(evaluationResult.value);

      if (response.data.fiber.success) {
        evaluateFiberResult(response.data.fiber);
      } 
      if (evaluationResult.value.result === "ok") {
        checkComplete.value = true;
        matchProducts();
        console.log(evaluationResult.value);
        return;
      }
      // router.push('#product-display');
    })
    .catch(error => {
      console.warn("availability error: ", error);
    })
    .finally(() => {
      loading.value = false;
    });
};

// const requestLocationAutocomplete = (input: string, addressUsage = 'PST') => {
//     axios.get(`${gaiaBasePath}/zipCities`, {
//         params: {
//             userId: scUserId,
//             official: true,
//             addressUsage,
//             zipCityType: 'domicile'
//         }
//     }).then((response) => {
//         console.log(response);
//     })
// }

export const useLocationManager = () => ({
  requestTownAutocomplete,
  checkLocation,
  shopMode,
  zipCodeResults: computed(() => zipCodeResults.value),
  townResults: computed(() => townResults.value),
  addressResults: computed(() => addressResults.value),
  zip: computed(() => zip.value),
  city: computed(() => city.value),
  street: computed(() => street.value),
  houseNr: computed(() => houseNr.value),
  checkComplete: computed(() => checkComplete.value),
  loading: computed(() => loading.value),
  connectionType: computed<"dsl" | "fiber">(() => evaluationResult.value.type),
  maxDown: computed<number>(
    () => evaluationResult.value.maxAccessSpeed.down / 1000
  ),
  resetData
});
