<template>
  <div
    class="relative"
    :class="[__elementspacingclass, __elementborderclass]"
    :style="{ 'background-color': color }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { elementSpacingMixin } from "../../mixins/elementSpacing.mixin";
import { elementBorderMixin } from "../../mixins/elementBorder.mixin";

export default {
  name: "BackgroundSection",
  mixins: [elementSpacingMixin, elementBorderMixin],
  props: {
    color: String
  }
};
</script>

<style scoped></style>
