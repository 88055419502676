import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["page", [
      _ctx.content ? 'px-standard' : '',
      _ctx.backgroundColor ? _ctx.backgroundColor : ''
    ]])
  }, [
    _createVNode(_component_metainfo),
    (_ctx.loadedPageComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.loadedPageComponent), {
          key: 0,
          pageTitle: _ctx.loadedPage.title.rendered
        }, null, 8, ["pageTitle"]))
      : _createCommentVNode("", true)
  ], 2))
}