
import { defineComponent, ref, computed, watchEffect } from "vue";
export default defineComponent({
  name: "ButtonControl",
  props: {
    label: {
      type: String,
      default: "BUTTON"
    },
    width: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    buttonStyle: {
      type: String,
      default: "primary"
    }
  }
});
