
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useMenuManager } from "@/api/menus/menuManager";
import { MenuItem } from "@/types/wp-types";
import { useResponsive } from "@/api/responsive/responsive";
export default defineComponent({
  name: "NavMobile",
  setup() {
    const router = useRouter();
    const { subNavItems, mainNavItems } = useMenuManager();
    const { menuOpen } = useResponsive();
    const selectedNavItem = ref<MenuItem>();

    const selectNavItem = (item: MenuItem) => {
      if (
        item.child_items &&
        item.child_items.length > 0 &&
        !(selectedNavItem.value === item)
      ) {
        selectedNavItem.value = item;
      } else if (selectedNavItem.value === item) {
        selectedNavItem.value = undefined;
      } else {
        router.push(item.url.replace(/^https:\/\/wpdev.netconnect.ch/, ""));
      }
    };

    return {
      menuOpen,
      mainNavItems,
      subNavItems,
      selectedNavItem,
      selectNavItem
    };
  }
});
