import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b88b7b40"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button-class text-xl font-bold", {
      disabled: _ctx.disabled,
      highlighted: _ctx.highlighted,
      [_ctx.buttonStyle]: _ctx.buttonStyle
    }]),
    disabled: _ctx.disabled,
    style: _normalizeStyle({ width: `${_ctx.width}` })
  }, _toDisplayString(_ctx.label), 15, _hoisted_1))
}