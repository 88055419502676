
import { ref, computed } from "vue";
// import Card from "./Card.vue";
import { useMailer } from "@/api/mails/mail";
import { useMenuManager } from "@/api/menus/menuManager";
import InputControl from "@/components/internal/InputControl.vue";
import ButtonControl from "@/components/internal/ButtonControl.vue";
export default {
  name: "ContactForm",
  components: { InputControl, ButtonControl },
  props: {
    showinfo: {
      type: Boolean,
      default: false
    },
    preselectedcategory: {
      type: String,
      default: null
    }
  },
  setup() {
    const menuManager = useMenuManager();
    const name = ref<string>("");
    const surname = ref<string>("");
    const email = ref<string>("");
    const category = ref<string>("");
    const subject = ref<string>("");
    const message = ref<string>("");
    const recipient = ref<string>("");
    const customerNumber = ref<string>("");
    const companyName = ref<string>("");
    const sending = ref<boolean>(false);
    const sendtext = ref<String>("Senden");
    const messageSent = ref<boolean>(false);
    const categoryDisabled = ref<boolean>(false);
    const subjectDisabled = ref<boolean>(false);
    const {
      getNonceAndSendMail,
      contactFormCategories,
      fixedCategory,
      fixedSubject,
      unsetFormCategory,
      unsetFormSubject
    } = useMailer();

    // console.log(fixedCategory.value);
    // if (fixedCategory.value) {
    //   category.value = fixedCategory.value;
    //   categoryDisabled.value = true;
    //   unsetFormCategory();
    //   if (fixedSubject.value) {
    //     subject.value = fixedSubject.value;
    //     subjectDisabled.value = true;
    //     unsetFormSubject();
    //   }
    // }

    const formFilled = computed(
      () =>
        name.value !== "" &&
        surname.value !== "" &&
        subject.value !== "" &&
        message.value !== ""
    );

    const onSend = () => {
      sending.value = true;
      getNonceAndSendMail({
        name: name.value,
        surname: surname.value,
        email: email.value,
        message: message.value,
        recipient: recipient.value,
        subject: `[${category.value}] ${subject.value}`,
        customerNumber: customerNumber.value,
        companyName: companyName.value
      })
        .then(response => {
          // console.log(response);
          // console.log("pushing gtm event formular_allgemein");
          window.dataLayer.push({ event: "formular_allgemein" });
          messageSent.value = true;
          sendtext.value = "Vielen Dank für Ihre Nachricht";
        })
        .catch(error => {
          console.warn(error);
        })
        .finally(() => {
          sending.value = false;
        });
    };

    return {
      name,
      surname,
      email,
      category,
      subject,
      recipient,
      message,
      sending,
      messageSent,
      customerNumber,
      companyName,
      categoryDisabled,
      subjectDisabled,
      contactFormCategories,
      sendtext,
      onSend,
      formFilled,
      contactItems: menuManager.contactItems
    };
  }
};
