
import {
  Component,
  computed,
  defineAsyncComponent,
  defineComponent,
  shallowRef,
  watchEffect
} from "vue";
import { TitleState, usePageManager } from "@/api/pages/pageManager";
import { useRoute } from "vue-router";
import components from "@/components/wp-widgets";
import { useMeta } from "vue-meta";

const PageComponent = defineComponent({
  props: {
    content: String,
    backgroundColor: String
  },
  emits: ["pageSwap"],
  setup(props, context) {
    // console.log(components);
    const loadedPageComponent = shallowRef<Component | null>(null);
    const route = useRoute();
    const pageManager = usePageManager();
    const showTitle = computed(() => {
      return pageManager.titleState.value === TitleState.VISIBLE;
    });
    // console.log("page props: ", props);
    if (props.content) {
      loadedPageComponent.value = defineAsyncComponent(async () => ({
        template: props.content,
        components
      }));
    } else {
      watchEffect(() => {
        // console.log("page component: ", route.fullPath);
        pageManager
          .getPageByURL(`https://wpdev.netconnect.ch${route.fullPath}`)
          .then(pageData => {
            // console.log(pageData);
            loadedPageComponent.value = defineAsyncComponent(async () => ({
              template: pageData.content.rendered,
              components
            }));
            // console.log(loadedPageComponent.value);
            context.emit("pageSwap", pageData);
            // useMeta({
            //   title: pageData.title.rendered
            // });
          })
          .catch(error => {
            // console.log("Error loading page: ", error);
          });
      });
    }
    return {
      loadedPage: pageManager.loadedPage,
      pageLoaded: pageManager.pageLoaded,
      titleState: pageManager.titleState,
      loadedPageComponent,
      showTitle
    };
  }
});

export default PageComponent;
