<template>
  <h2 class="my-xl">Allgemeiner Status</h2>
  <div
    v-if="statusData.message"
    class="flex bg-error-light justify-center items-center py-md mb-standard"
  >
    <p class="m-auto text-white">{{ statusData.message }}</p>
  </div>
  <div class="overflow-x-auto">
    <table class="w-1/3 my-xl">
      <thead>
        <tr>
          <th class="w-1/3">
            <img
              src="https://wpdev.netconnect.ch/wp-content/uploads/2021/11/nc_table.png"
            />
          </th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            NetConnect
          </td>
          <td>
            <NetworkStatusLabel
              :status="statusData.netconnect"
              :status-loading="statusDataLoading"
            />
          </td>
        </tr>
        <tr>
          <td>
            NetMPLS
          </td>
          <td>
            <NetworkStatusLabel
              :status="statusData.netmpls"
              :status-loading="statusDataLoading"
            />
          </td>
        </tr>
        <tr>
          <td>
            Ethernet Access
          </td>
          <td>
            <NetworkStatusLabel
              :status="statusData.ethernet_access"
              :status-loading="statusDataLoading"
            />
          </td>
        </tr>
        <tr>
          <td>
            NetDSL Business
          </td>
          <td>
            <NetworkStatusLabel
              :status="statusData.netdsl_business"
              :status-loading="statusDataLoading"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--  <h2>Informationen zu Ihrem Anschluss</h2>-->
  <div class="max-w-body-width w-body-width mx-auto mb-md">
    <!--    <div class="flex my-standard h-cell">-->
    <!--      <input-->
    <!--        class="flex-grow mr-standard px-standard shadow-inset"-->
    <!--        type="text"-->
    <!--        placeholder="Anschlussnummer"-->
    <!--        v-model="connectionNumber"-->
    <!--      />-->
    <!--      <button-->
    <!--        class="flex w-lg p-3 mr-standard text-xl"-->
    <!--        :class="{-->
    <!--          'bg-primary': previousConnectionNumber,-->
    <!--          'bg-primary-disabled': !previousConnectionNumber-->
    <!--        }"-->
    <!--        :disabled="!previousConnectionNumber"-->
    <!--        @click="getConnectionData(previousConnectionNumber)"-->
    <!--      >-->
    <!--        <font-awesome-icon-->
    <!--          class="text-primary-contrast m-auto"-->
    <!--          icon="redo-alt"-->
    <!--        ></font-awesome-icon>-->
    <!--      </button>-->
    <!--      <button-->
    <!--        class="flex-quarter bg-primary text-primary-contrast"-->
    <!--        @click="getConnectionData(connectionNumber)"-->
    <!--      >-->
    <!--        Anschluss überprüfen-->
    <!--      </button>-->
    <!--    </div>-->
    <!--    <hr class="divider" />-->
    <!--    <div-->
    <!--      v-if="connectionData.length === 0 || connectionDataLoading"-->
    <!--      class="flex h-standard-image"-->
    <!--    >-->
    <!--      <p class="text-3xl m-auto">-->
    <!--        {{-->
    <!--          connectionDataLoading-->
    <!--            ? "Getting Data"-->
    <!--            : connectionChecked-->
    <!--            ? "Momentan sind keine Störungen für Ihren Anschluss bekannt."-->
    <!--            : "Für Überprüfung bitte Anschlussnummer eingeben"-->
    <!--        }}-->
    <!--      </p>-->
    <!--    </div>-->
    <!--    <div v-else class="mb-standard">-->
    <!--      <div class="flex h-cell items-center border-b-md border-font">-->
    <!--        <p class="flex-quarter text-lg">Betroffener Service</p>-->
    <!--        <p class="flex-quarter text-lg">Start</p>-->
    <!--        <p class="flex-quarter text-lg">Ende</p>-->
    <!--        <p class="flex-quarter text-lg">Störungsnummer</p>-->
    <!--      </div>-->
    <!--      <div-->
    <!--        v-for="(issue, i) in connectionData"-->
    <!--        :key="`connection-data-${i}`"-->
    <!--        class="flex h-cell items-center"-->
    <!--        :class="{-->
    <!--          'border-b-standard border-font': i < connectionData.length - 1-->
    <!--        }"-->
    <!--      >-->
    <!--        <p class="flex-quarter text-lg">{{ issue.service }}</p>-->
    <!--        <p class="flex-quarter text-lg">{{ issue.startDateTime }}</p>-->
    <!--        <p class="flex-quarter text-lg">{{ issue.endDateTime }}</p>-->
    <!--        <p class="flex-quarter text-lg">{{ issue.outage_id }}</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <hr class="divider" />-->
    <h2 class="mb-xl text-center">Aktuelle<br />Wartungs&shy;arbeiten</h2>
    <div
      v-if="maintenanceData.length === 0"
      class="flex h-standard-image text-3xl"
    >
      <p>
        {{
          maintenanceDataLoading
            ? "Wartungsarbeiten werden geladen..."
            : "Momentan werden keine Wartungsarbeiten durchgeführt."
        }}
      </p>
    </div>
    <div v-else class="overflow-x-auto">
      <table class="mb-2xl">
        <thead>
          <tr>
            <th>
              <img
                src="https://wpdev.netconnect.ch/wp-content/uploads/2021/11/nc_table.png"
              />
            </th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(maintenance, i) in maintenanceData"
            :key="`maintenance-data-${i}`"
          >
            <td>
              <p class="has-large-font-size mx-0 mb-lg">
                {{
                  `${!customEntries.includes(maintenance) ? "Swisscom: " : ""}${
                    maintenance.title
                  }`
                }}
              </p>
              <p class="my-auto mx-0 font-normal text-font">
                {{ maintenance.description }}
              </p>
            </td>
            <td>
              <div
                class="flex flex-col pr-xl justify-center whitespace-no-wrap"
              >
                <span class="mb-md">Start: {{ maintenance.start }}</span>
                <span class="mb-md"
                  >Ende:
                  {{ maintenance.end ? maintenance.end : "Unbekannt" }}</span
                >
                <span
                  :class="{
                    'text-success': maintenance.status === 'Ende',
                    'text-warning': new Date(maintenance.start) > new Date(),
                    'text-error':
                      new Date(maintenance.start) < new Date() &&
                      maintenance.status !== 'Ende'
                  }"
                >
                  {{
                    new Date(maintenance.start) > new Date()
                      ? "Geplant"
                      : maintenance.status === "Ende"
                      ? "Abgeschlossen"
                      : "Aktuell"
                  }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import NetworkStatusLabel from "../internal/NetworkStatusLabel.vue";
import { useConnectionStatusManager } from "../../api/connection-status/connectionStatusManager";
import { ref } from "vue";

export default {
  name: "ConnectionStatus",
  components: { NetworkStatusLabel },
  setup() {
    const connectionStatusManager = useConnectionStatusManager();
    const connectionNumber = ref("");

    connectionStatusManager.getMaintenanceData();
    connectionStatusManager.getStatusData();

    const getConnectionData = cNumber => {
      connectionStatusManager.getConnectionData(cNumber);
    };

    return {
      maintenanceData: connectionStatusManager.maintenanceData,
      connectionData: connectionStatusManager.connectionData,
      statusData: connectionStatusManager.statusData,
      connectionDataLoading: connectionStatusManager.connectionDataLoading,
      maintenanceDataLoading: connectionStatusManager.maintenanceDataLoading,
      statusDataLoading: connectionStatusManager.statusDataLoading,
      previousConnectionNumber:
        connectionStatusManager.previousConnectionNumber,
      connectionChecked: connectionStatusManager.connectionChecked,
      connectionNumber,
      customEntries: connectionStatusManager.customEntries,
      getConnectionData
    };
  }
};
</script>

<style scoped></style>
