import { withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-def2692c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "placeholder", "value", "required", "readonly"]
const _hoisted_3 = ["value", "placeholder"]
const _hoisted_4 = { class: "float-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutocompleteBox = _resolveComponent("AutocompleteBox")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative mt-lg",
    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.inputElement.focus()))
  }, [
    _createElementVNode("label", { for: _ctx.name }, [
      (_ctx.type !== 'textarea')
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            class: "w-full",
            type: _ctx.type,
            placeholder: _ctx.name,
            value: _ctx.modelValue,
            required: _ctx.required,
            readonly: _ctx.readonly,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus', $event))),
            onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur', $event))),
            onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('keydown.enter', $event)), ["enter"])),
            ref: "inputElement"
          }, null, 40, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.type === 'textarea')
        ? (_openBlock(), _createElementBlock("textarea", {
            key: 1,
            class: "w-full",
            rows: "8",
            value: _ctx.modelValue,
            placeholder: _ctx.name,
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 40, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.name), 1)
    ], 8, _hoisted_1),
    _createVNode(_component_AutocompleteBox, {
      "options-loading": _ctx.optionsLoading,
      options: _ctx.autocompleteOptions,
      "input-element": _ctx.inputElement,
      onSelected: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('autocompleteSelected', $event)))
    }, null, 8, ["options-loading", "options", "input-element"])
  ]))
}