import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_NavMobile = _resolveComponent("NavMobile")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isMobile && !_ctx.isTablet)
      ? (_openBlock(), _createBlock(_component_Navbar, {
          key: 0,
          "is-scrolled-down": _ctx.isScrolledDown
        }, null, 8, ["is-scrolled-down"]))
      : (_openBlock(), _createBlock(_component_NavMobile, { key: 1 })),
    _createElementVNode("div", {
      id: "content",
      class: _normalizeClass(["content overflow-y-auto", { 'nav-collapsed': _ctx.isScrolledDown || _ctx.isSmaller }]),
      ref: "contentElement",
      onScroll: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getScrollHeight()))
    }, [
      _createVNode(_component_router_view),
      _createVNode(_component_Footer)
    ], 34)
  ], 64))
}