
import { defineComponent, ref, computed, watchEffect } from "vue";
import AutocompleteBox from "../internal/AutocompleteBox.vue";
export default defineComponent({
  name: "InputControl",
  components: { AutocompleteBox },
  props: {
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      default: "input"
    },
    modelValue: {
      type: String,
      default: ""
    },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    autocompleteOptions: { type: Array, default: () => [] },
    optionsLoading: { type: Boolean, default: false },
    debounce: { type: Function, default: null }
  },
  emits: [
    "update:modelValue",
    "input",
    "focus",
    "blur",
    "keydown.enter",
    "autocompleteSelected"
  ],
  setup(props, context) {
    const inputElement = ref<HTMLInputElement>();
    const onInput = (event: Event) => {
      const element = event.target as HTMLInputElement;
      if (props.debounce) {
        props.debounce(element.value);
      }
      context.emit("input", event);
      context.emit("update:modelValue", element.value);
    };
    return {
      inputElement,
      onInput
    };
  }
});
