
import { defineComponent } from "vue";

export default defineComponent({
  name: "NetworkStatusLabel",
  props: {
    label: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    statusLoading: {
      type: Boolean,
      default: true
    }
  }
  // setup(props) {
  //
  // }
});
