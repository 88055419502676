import { ref, computed } from "vue";

const mobileBreakpoint = ref<number>();
const tabletBreakpoint = ref<number>();
const hdBreakpoint = ref<number>();

const isMobile = ref<boolean>(false);
const isTablet = ref<boolean>(false);
const isHD = ref<boolean>(false);
const isDesktop = ref<boolean>(false);
const menuOpen = ref<boolean>(false);

const isSmaller = computed(() => isMobile.value || isTablet.value);

window.addEventListener("resize", event => {
  // console.log(document.body.clientWidth, mobileBreakpoint);
  if (mobileBreakpoint.value) {
    isMobile.value = document.body.clientWidth < mobileBreakpoint.value;
  }
  if (tabletBreakpoint.value) {
    isTablet.value = mobileBreakpoint.value
      ? document.body.clientWidth < tabletBreakpoint.value &&
        document.body.clientWidth >= mobileBreakpoint.value
      : document.body.clientWidth < tabletBreakpoint.value;
  }
  if (hdBreakpoint.value) {
    isHD.value = tabletBreakpoint.value
      ? document.body.clientWidth < hdBreakpoint.value &&
        document.body.clientWidth >= tabletBreakpoint.value
      : document.body.clientWidth < hdBreakpoint.value;
    isDesktop.value = document.body.clientWidth >= hdBreakpoint.value;
  }
  console.log(isMobile.value);
  console.log(isTablet.value);
  console.log(isHD.value);
  console.log("\n\n");
});

const setMobileBreakpoint = (bp: string): void => {
  mobileBreakpoint.value = parseInt(bp);
  isMobile.value = document.body.clientWidth < mobileBreakpoint.value;
};

const setTabletBreakpoint = (bp: string): void => {
  tabletBreakpoint.value = parseInt(bp);
  isTablet.value = document.body.clientWidth < tabletBreakpoint.value;
};

const setHDBreakpoint = (bp: string): void => {
  hdBreakpoint.value = parseInt(bp);
  isHD.value = document.body.clientWidth < hdBreakpoint.value;
};

export const useResponsive = () => ({
  isMobile: computed(() => isMobile.value),
  isTablet: computed(() => isTablet.value),
  isHD: computed(() => isHD.value),
  isDesktop: computed(() => isDesktop.value),
  menuOpen,
  setMobileBreakpoint,
  setTabletBreakpoint,
  setHDBreakpoint,
  isSmaller
});
