
import { ref, computed, nextTick } from "vue";
import ButtonControl from "../internal/ButtonControl.vue";
import InputControl from "../internal/InputControl.vue";
import SwitchControl from "../internal/SwitchControl.vue";
import {
  LocationOrigins,
  useLocationManager
} from "@/api/location/locationManager";
import useDebounce from "../../composition/debounce";
import value from "*.json";
import { useRouter } from "vue-router";
export default {
  name: "LocationCheck",
  components: {
    InputControl,
    ButtonControl,
    SwitchControl
  },
  props: {
    addbodyinset: String,
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const {
      zipCodeResults,
      townResults,
      addressResults,
      requestTownAutocomplete,
      checkLocation,
      loading,
      shopMode
    } = useLocationManager();
    const locationManager = useLocationManager();

    const router = useRouter();

    const mode = ref<string>("private");

    const zipFocussed = ref(false);
    const townFocussed = ref(false);
    const streetFocussed = ref(false);
    const houseNumberFocussed = ref(false);

    const zipAutocompleted = ref(false);
    const streetAutocompleted = ref(false);
    const housenumberAutocompleted = ref(false);

    const zipInput = ref<HTMLInputElement | null>(null);
    const townInput = ref<HTMLInputElement | null>(null);
    const streetInput = ref<HTMLInputElement | null>(null);
    const housenumberInput = ref<HTMLInputElement | null>(null);

    const zipLoading = ref(false);
    const townLoading = ref(false);
    const streetLoading = ref(false);

    const zip = ref(locationManager.zip.value || "");
    const town = ref(locationManager.city.value || "");
    const street = ref(locationManager.street.value || "");
    const houseNumber = ref(locationManager.houseNr.value || "");

    const productsDisplay = document.querySelector("#product-display");
    const contentElement = document.querySelector("#content");

    if (productsDisplay) {
      productsDisplay.scrollTop -= 20;
    }

    const formComplete = computed(() => {
      // console.log(
      //   "values: ",
      //   zip.value,
      //   town.value,
      //   street.value,
      //   houseNumber.value
      // );
      return zip.value && street.value && houseNumber.value;
    });
    const showZipAutocomplete = computed(() => {
      return (
        zipFocussed.value &&
        !zipAutocompleted.value &&
        zipCodeResults.value.length > 0
      );
    });
    const showStreetAutocomplete = computed(() => {
      return (
        streetFocussed.value &&
        !streetAutocompleted.value &&
        addressResults.value.length
      );
    });
    const showHouseNumberAutocomplete = computed(() => {
      return (
        houseNumberFocussed.value &&
        !housenumberAutocompleted.value &&
        addressResults.value.length > 0
      );
    });

    const zipCodeDebounce = useDebounce(value => {
      zipLoading.value = true;
      requestTownAutocomplete(value, LocationOrigins.ZIPCODE).finally(() => {
        zipLoading.value = false;
      });
    });
    const townDebounce = useDebounce(value => {
      townLoading.value = true;
      requestTownAutocomplete(value, LocationOrigins.TOWNS).finally(() => {
        townLoading.value = false;
      });
    });
    const streetDebounce = useDebounce(value => {
      streetLoading.value = true;
      requestTownAutocomplete(
        `${value} ${zip.value} ${town.value} ${street.value}`.trim(),
        LocationOrigins.ADDRESS
      ).finally(() => {
        streetLoading.value = false;
      });
    });

    const handleZipAutocomplete = (value: string) => {
      // console.log("zip autocomplete: ", value);
      if (!value) return;
      value = value.replace(/\(.*\)/, "").trim();
      const [zipCode, townString] = value.split(" - ");
      // console.log(zipCode, town);
      zip.value = zipCode;
      town.value = townString;
      zipCodeDebounce(zipCode);
      townDebounce(town);
      zipAutocompleted.value = true;
      nextTick(() => {
        if (streetInput.value) streetInput.value.focus();
      });
    };
    const handleAddressAutocomplete = (value: string) => {
      if (!value) return;
      const splitValue = value.split(" ");
      let streetString = "";
      let houseNumberString = "";
      splitValue.some(str => {
        if (!/\d/.test(str)) {
          streetString += str + " ";
        } else if (!houseNumberString) {
          houseNumberString = str;
          return true;
        }
      });

      if (streetString) {
        street.value = streetString.trim();
        streetAutocompleted.value = true;
      }
      if (houseNumberString) {
        houseNumber.value = houseNumberString;
        housenumberAutocompleted.value = true;
      }
      streetDebounce(value);
    };

    const onCheckLocation = () => {
      if (!formComplete.value) return;
      if (!locationManager.checkComplete.value) {
        if( town.value === "" )
        {
          const zt = zipCodeResults.value[0].replace(/\(.*\)/, "").trim();
          const [zipCode, townString] = zt.split(" - ");
          town.value = townString;
        }
        checkLocation({
          zip: zip.value,
          city: town.value,
          street: street.value,
          houseNr: houseNumber.value
        }).then(() => {
          window.dataLayer.push({ event: "availability-checked" });
          if (productsDisplay) {
            productsDisplay.scrollIntoView({
              behavior: "smooth"
            });
            // if (contentElement) {
            //   contentElement.scrollTop -= 20;
            // }
          }
        });
      } else {
        locationManager.resetData();
        zip.value = "";
        town.value = "";
        street.value = "";
        houseNumber.value = "";
      }
    };

    // const resetForm = () => {
    //   locationManager.resetData();
    //   zip.value = "";
    //   town.value = "";
    //   street.value = "";
    //   houseNumber.value = "";
    // };

    return {
      requestTownAutocomplete,
      zipCodeDebounce,
      townDebounce,
      streetDebounce,
      zipCodeResults,
      townResults,
      addressResults,
      zipFocussed,
      townFocussed,
      streetFocussed,
      houseNumberFocussed,
      zipInput,
      townInput,
      streetInput,
      housenumberInput,
      handleZipAutocomplete,
      handleAddressAutocomplete,
      onCheckLocation,
      zipAutocompleted,
      streetAutocompleted,
      housenumberAutocompleted,
      formComplete,
      zip,
      town,
      street,
      houseNumber,
      showZipAutocomplete,
      showStreetAutocomplete,
      showHouseNumberAutocomplete,
      loading,
      zipLoading,
      checkComplete: locationManager.checkComplete,
      shopMode
    };
  }
};
