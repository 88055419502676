
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useMenuManager } from "@/api/menus/menuManager";
export default defineComponent({
  name: "Navbar",
  props: {
    isScrolledDown: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter();
    const { subNavItems, mainNavItems } = useMenuManager();
    const hoveredNavItem = ref({});
    const hoveredNavChild = ref({});
    const onNavHover = (item: Record<string, never>) => {
      hoveredNavItem.value = item;
    };
    const onNavChildHover = (item: Record<string, never>) => {
      hoveredNavChild.value = item;
    };
    const onNavLeave = (url: string) => {
      hoveredNavItem.value = {};
      if (!url) return;
      router.push(url);
    };
    return {
      mainNavItems,
      subNavItems,
      hoveredNavItem,
      onNavHover,
      onNavChildHover,
      onNavLeave
    };
  }
});
