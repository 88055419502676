import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a81ba0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(["transition-all duration-300", { 'text-black font-bold': _ctx.modelValue === _ctx.offValue }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.offValue)))
    }, _toDisplayString(_ctx.offLabel), 3),
    _createElementVNode("div", {
      class: "switch-container mx-md",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (
        _ctx.$emit('update:modelValue', _ctx.modelValue === _ctx.onValue ? _ctx.offValue : _ctx.onValue)
      ))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["switch", {
          off: _ctx.modelValue === _ctx.offValue,
          on: _ctx.modelValue === _ctx.onValue
        }])
      }, null, 2)
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(["transition-all duration-300", { 'text-black font-bold': _ctx.modelValue === _ctx.onValue }]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.onValue)))
    }, _toDisplayString(_ctx.onLabel), 3)
  ]))
}