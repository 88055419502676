
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "SwitchControl",
  props: {
    offLabel: {
      type: String,
      default: "off"
    },
    onLabel: {
      type: String,
      default: "on"
    },
    offValue: {
      default: false
    },
    onValue: {
      default: true
    },
    modelValue: {
      default: false
    }
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    if (!props.modelValue) {
      context.emit("update:modelValue", props.offValue);
    }
  }
});
