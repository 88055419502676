
import {
  defineComponent,
  ref,
  watchEffect,
  onBeforeMount,
  computed
} from "vue";
import { useActiveMeta, useMeta } from "vue-meta";
import { usePageManager } from "@/api/pages/pageManager";
import { useProductsManager } from "@/api/products/productsManager";
import { useLocationManager } from "@/api/location/locationManager";
import { useResponsive } from "@/api/responsive/responsive";
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import NavMobile from "@/components/layout/NavMobile.vue";

const Component = defineComponent({
  components: { Navbar, NavMobile, Footer },
  setup(props, context) {
    const { isMobile, isTablet, isSmaller } = useResponsive();
    const { meta } = useMeta({});
    const { loadedPage } = usePageManager();
    const productsManager = useProductsManager();
    const locationManager = useLocationManager();

    const name = ref<string>("");
    const surname = ref<string>("");
    const email = ref<string>("");
    const phone = ref<string>("");

    const contentElement = ref<HTMLElement | null>();
    const contentScroll = ref<number>(0);

    const isScrolledDown = computed(() => {
      return isMobile.value || contentScroll.value > 80;
    });

    const getScrollHeight = () => {
      if (!contentElement.value) return;
      contentScroll.value = contentElement.value.scrollTop;
    };

    onBeforeMount(() => {
      const head = document.querySelector("head");
      if (head) {
        Array.from(head.childNodes).forEach(node => {
          const el = node as HTMLElement;
          // console.dir(el);
          if (
            !(
              (
                el.outerHTML &&
                (el.outerHTML.includes("charset") ||
                  el.outerHTML.includes("Cache-Control") ||
                  el.outerHTML.includes("Pragma") ||
                  el.outerHTML.includes("Expires") ||
                  el.outerHTML.includes("name") ||
                  el.outerHTML.includes("viewport"))
              ) //||
              // TODO: Uncomment when responsive design is done
              // el.outerHTML.includes("viewport"))
            ) &&
            (el.nodeName === "META" ||
              el.nodeName === "TITLE" ||
              el.nodeName === "STYLE" ||
              (el.outerHTML && el.outerHTML.includes('as="style"')))
          )
            node.remove();
        });
      }
    });
    watchEffect(() => {
      if (!loadedPage.value.title) return;
      meta.title = loadedPage.value.yoast_title;
      meta.meta = [...loadedPage.value.yoast_meta];
    });

    return {
      metadata: useActiveMeta(),
      metaData: useActiveMeta(),
      chosenProduct: productsManager.chosenProduct,
      unsetChosenProduct: productsManager.unsetChosenProduct,
      name,
      surname,
      email,
      phone,
      zipcode: locationManager.zip,
      town: locationManager.city,
      street: locationManager.street,
      houseNr: locationManager.houseNr,
      isScrolledDown,
      contentElement,
      getScrollHeight,
      isMobile,
      isTablet,
      isSmaller
    };
  }
});

export default Component;
