import { ref, computed, Component } from "vue";
import axios, { AxiosResponse } from "axios";

export enum TitleState {
  HIDDEN,
  VISIBLE
}

const pages = ref<any[]>([]);
const loadedPage = ref({});
const pageLoaded = ref<boolean>(false);
// const loading = ref<boolean>(false)
const titleState = ref<TitleState>(TitleState.VISIBLE);

const getPages = (): Promise<AxiosResponse | void> => {
  return axios
    .get("https://wpdev.netconnect.ch/wp-json/wp/v2/pages", {
      params: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        per_page: 100
      }
    })
    .then(response => {
      // console.log(response);
      pages.value = [...pages.value, ...response.data];
    })
    .catch(error => {
      console.warn(error);
    });
};

export interface GetPageCallback {
  (): Component;
}

// const getPage = (pageId: number): Promise<string>  => {
//     loading.value = true
//     return axios.get(`https://wpdev.netconnect.ch/wp-json/wp/v2/pages/${pageId}`).then((response) => {
//         console.log(response);
//         loadedPage.value = response.data;
//         pageLoaded.value = true;
//         return response.data.content.rendered;
//     }).catch((error) => {
//         pageLoaded.value = false
//         return error
//     }).finally(() => {
//         loading.value = false;
//     });
// }

const getCachedPage = (url: string): Record<string, any> => {
  console.log(pages.value);
  return pages.value.find(page => {
    return page.link === url.replace(/wpdev.netconnect.ch$/, "");
  });
};

const getPageByURL = (url: string): Promise<Record<string, any>> => {
  console.log(url);
  return new Promise((resolve, reject) => {
    url = url.split("#")[0];
    url = url.split("?")[0];
    if (url !== "https://wpdev.netconnect.ch/") {
      url = url.replace(/\/$/, "");
    }
    console.log(url);
    const cachedPage = getCachedPage(url);
    console.log(cachedPage);
    if (!cachedPage) {
      // console.log('fail');
      reject("Unknown Page");
      return;
    }
    loadedPage.value = cachedPage;
    console.log(loadedPage.value);
    pageLoaded.value = true;
    resolve(cachedPage);
  });
};

const addPages = (pageList: never[]): void => {
  pages.value = [...pages.value, ...pageList];
  // console.log('pages added: ', pageList, pages.value);
};

const setPages = (pageList: never[]): void => {
  // console.log(pageList);
  pages.value = pageList;
};

const setTitleState = (state: TitleState): void => {
  titleState.value = state;
};

export const usePageManager = () => ({
  getPages,
  addPages,
  getPageByURL,
  setPages,
  setTitleState,
  pages: computed(() => pages.value),
  loadedPage: computed<Record<string, any>>(() => loadedPage.value),
  pageLoaded: computed(() => pageLoaded.value),
  titleState: computed(() => titleState.value)
});
