
import { useMailer } from "../../api/mails/mail";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import ButtonControl from "@/components/internal/ButtonControl.vue";

export default defineComponent({
  name: "ButtonLink",
  props: {
    label: String,
    link: String,
    contactformcategory: String,
    contactformsubject: String
  },
  components: { ButtonControl },
  setup(props) {
    const router = useRouter();
    const { setFormCategory, setFormSubject } = useMailer();
    const onClick = () => {
      if (props.contactformcategory) {
        setFormCategory(props.contactformcategory);
      }
      if (props.contactformsubject) {
        setFormSubject(props.contactformsubject);
      }
      if (props.link) {
        router.push(props.link.replace(/^https:\/\/wpdev.netconnect.ch/, ""));
      }
    };
    return {
      onClick
    };
  }
});
