import { ref, computed } from 'vue'

const faqs = ref<Record<string, any>[]>([]);
const selectedFAQ = ref<Record<string, any>>({})

const setFAQs = (faqList: Record<string, any>[]): void => {
    faqs.value = faqList
}

const selectFAQ = (faqId: string) => {
    if(!faqId) selectedFAQ.value = {};
    const faq = faqs.value.find((faq) => faq.id === faqId);
    selectedFAQ.value = faq || {};
}

export const useFAQManager = () => ({
    faqs: computed(() => faqs.value),
    selectedFAQ: computed(() => selectedFAQ.value),
    setFAQs,
    selectFAQ
})
