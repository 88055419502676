export enum PaymentInterval {
    ONCE,
    MONTHLY,
    YEARLY
}

export const ProductCategories = {
    internet: 'DSL / Fiber',
    ip: 'IP Adressen',
    router: 'Router',
    bridge: 'Bridges',
    sim: 'Backup SIM'
}
