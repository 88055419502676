
import { defineComponent, ref } from "vue";
import { useMenuManager } from "@/api/menus/menuManager";
import { useResponsive } from "@/api/responsive/responsive";
export default defineComponent({
  name: "Footer",
  setup() {
    const { isMobile } = useResponsive();
    const {
      footerLegalItems,
      footerAboutItems,
      contactItems
    } = useMenuManager();
    return {
      footerLegalItems,
      footerAboutItems,
      contactItems,
      isMobile
    };
  }
});
