import { computed } from "vue";

let baseUrl = ""

const setBaseUrl = (url: string) => {
    // console.log('set base url: ', baseUrl)
    baseUrl = url
}

export const env = () => ({
    baseUrl: computed(() => baseUrl || ""),
    setBaseUrl
})
