import { ref } from 'vue';
import axios, {AxiosResponse} from "axios";
import {MenuItem} from "@/types/wp-types";

const menus = ref([]);
const mainNavItems = ref<MenuItem[]>([]);
const subNavItems = ref<MenuItem[]>([]);
const footerAboutItems = ref<MenuItem[]>([]);
const footerLegalItems = ref<MenuItem[]>([]);
const contactItems = ref<MenuItem[]>([]);

const getMenus = (): Promise<AxiosResponse | void> => {
    return new Promise<void>((resolve, reject) => {
        axios.get('https://wpdev.netconnect.ch/wp-json/menus/v1/menus').then((response) => {
            menus.value = response.data
            const promises: Promise<any>[] = [];
            response.data.forEach((menu: Record<string, any>) => {
                if(menu.slug === "main") {
                    promises.push(axios.get('https://wpdev.netconnect.ch/wp-json/menus/v1/menus/main').then((response) => {
                        mainNavItems.value = response.data.items;
                    }));
                }
                if(menu.slug === "sub") {
                    promises.push(axios.get('https://wpdev.netconnect.ch/wp-json/menus/v1/menus/sub').then((response) => {
                        subNavItems.value = response.data.items;
                    }));
                }
                if(menu.slug === "footer") {
                    promises.push(axios.get('https://wpdev.netconnect.ch/wp-json/menus/v1/menus/footer').then((response) => {
                        footerAboutItems.value = response.data.items;
                    }));
                }
            });
            Promise.all(promises).then(() => {
                resolve();
            });
        });
    })
};

const setMenus = (menuList: never[]): void => {
    menus.value = menuList;
}
const setMainNavItems = (navList: MenuItem[]): void => {
    console.log(navList);
    mainNavItems.value = navList;
}
const setSubNavItems = (navList: MenuItem[]): void => {
    subNavItems.value = navList;
}
const setFooterAboutItems = (navList: MenuItem[]): void => {
    footerAboutItems.value = navList;
}
const setFooterLegalItems = (navList: MenuItem[]): void => {
    footerLegalItems.value = navList;
}
const setContactItems = (items: never[]): void => {
    // console.log(items);
    contactItems.value = items;
}

export const useMenuManager = () => ({
    getMenus,
    setMenus,
    setMainNavItems,
    setSubNavItems,
    setFooterAboutItems,
    setFooterLegalItems,
    setContactItems,
    menus,
    mainNavItems,
    subNavItems,
    footerAboutItems,
    footerLegalItems,
    contactItems
})
