import { ref, computed } from 'vue';
import axios, {AxiosResponse} from "axios";

const connectionData = ref<any[]>([]);
const maintenanceData = ref<any[]>([]);
const statusData = ref<Record<string, any>>({});
const customEntries = ref<Record<string, string>[]>([])
const statusDataLoading = ref(false);
const connectionDataLoading = ref(false);
const maintenanceDataLoading = ref(true);
const previousConnectionNumber = ref<string>('');
const connectionChecked = ref(false);

const getStatusData = () => {
    statusData.value = {};
    statusDataLoading.value = true;
    return axios.get('https://wpdev.netconnect.ch/wp-json/acf/v3/network_status').then((response) => {
        // console.log(response);
        statusData.value = response.data[0].acf;
        Object.keys(statusData.value).forEach((key) => {
            if(key.includes('custom_entry') && statusData.value[key].title && statusData.value[key].start) {
                const customEntry = statusData.value[key];
                customEntries.value.push({
                    title: customEntry.title,
                    description: customEntry.description,
                    start: customEntry.start,
                    end: customEntry.end,
                    status: customEntry.end && new Date(customEntry.end) < new Date() ? 'Ende' : 'Update'
                })
            }
        });
        customEntries.value.sort((a, b) => {
            const aStartDate = new Date(a.start);
            const bStartDate = new Date(b.start);
            // console.log(aStartDate, bStartDate, aStartDate < bStartDate ? 1 : -1)
            return aStartDate < bStartDate ? 1 : -1;
        })
        // console.log(customEntries.value);
    }).finally(() => {
        statusDataLoading.value = false;
    })
}

const getConnectionData = (cNumber: string) => {
    connectionDataLoading.value = true;
    previousConnectionNumber.value = cNumber;
    return axios.get(`https://wpdev.netconnect.ch/wp-json/swisscom/v1/connection/${cNumber}`).then((response) => {
        // console.log(response);
        connectionData.value = response.data
    }).catch((error) => {
        // console.warn(error);
    }).finally(() => {
        connectionDataLoading.value = false;
        connectionChecked.value = true;
    });
}

const getMaintenanceData = () => {
    maintenanceDataLoading.value = true;
    return axios.get(`https://wpdev.netconnect.ch/wp-json/swisscom/v1/status/`).then((response) => {
        // console.log(response);
        maintenanceData.value = response.data;
    }).catch((error) => {
        console.warn(error);
    }).finally(() => {
        maintenanceDataLoading.value = false;
    });
}

export const useConnectionStatusManager = () => ({
    connectionData: computed(() => connectionData.value),
    maintenanceData: computed(() => {
        const allData = [...maintenanceData.value, ...customEntries.value];
        allData.sort((a, b) => {
            const aStartDate = new Date(a.start);
            const bStartDate = new Date(b.start);
            return aStartDate < bStartDate ? 1 : -1;
        });
        return allData;
    }),
    statusData: computed(() => statusData.value),
    customEntries: computed(() => customEntries.value),
    connectionDataLoading: computed(() => connectionDataLoading.value),
    maintenanceDataLoading: computed(() => maintenanceDataLoading.value),
    statusDataLoading: computed(() => statusDataLoading.value),
    previousConnectionNumber: computed(() => previousConnectionNumber.value),
    connectionChecked: computed(() => connectionChecked.value),
    getConnectionData,
    getMaintenanceData,
    getStatusData
})
