import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./css/tailwind.css";
import dataJSON from "./wp-data/data.json";
import { useMenuManager } from "@/api/menus/menuManager";
import { useProductsManager } from "@/api/products/productsManager";
import { usePostManager } from "@/api/posts/postManager";
import { usePageManager } from "@/api/pages/pageManager";
import { env } from "@/api/env";

import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faRedoAlt,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useFAQManager } from "@/api/faqs/faqManager";
import { createMetaManager, deepestResolver, defaultConfig } from "vue-meta";
import { useMailer } from "@/api/mails/mail";
import { useResponsive } from "@/api/responsive/responsive";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const app = createApp(App);

app.use(router);
app.use(createMetaManager(false, {
    ...defaultConfig,
    ...{
        'description': {
            tag: 'meta',
            keyAttribute: 'name',
            valueAttribute: 'content'
        },
        'robots': {
            tag: 'meta',
            keyAttribute: 'name',
            valueAttribute: 'content'
        },
    }
}));
// Font awesome setup
library.add(faCheck as IconDefinition);
library.add(faRedoAlt as IconDefinition);
library.add(faChevronDown as IconDefinition);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("pulse-loader", PulseLoader);

const isPrerender = !!(window as any)["__PRERENDER"];

const { setBaseUrl, baseUrl } = env();
const menuManager = useMenuManager();
const productsManager = useProductsManager();
const pageManager = usePageManager();
const prerenderData = dataJSON;
const faqManager = useFAQManager();
const postManager = usePostManager();
const mailer = useMailer();
const responsive = useResponsive();

const readPageData = () => {
  const {
    pages,
    menus,
    mainNavItems,
    subNavItems,
    footerAboutItems,
    footerLegalItems,
    products,
    faqs,
    contactItems,
    contactFormCategories,
    baseUrl,
    mobileBreakpoint,
    tabletBreakpoint,
    hdBreakpoint
    // optionalProducts
  } = prerenderData;
  setBaseUrl(baseUrl);
  menuManager.setMenus(menus);
  menuManager.setMainNavItems(mainNavItems);
  menuManager.setSubNavItems(subNavItems);
  menuManager.setFooterAboutItems(footerAboutItems);
  menuManager.setFooterLegalItems(footerLegalItems);
  menuManager.setContactItems(contactItems);
  pageManager.setPages(pages);
  productsManager.setProducts(products);
  faqManager.setFAQs(faqs);
  postManager.setPostsFromPages(pages);
  mailer.setContactFormCategories(contactFormCategories);
  responsive.setMobileBreakpoint(mobileBreakpoint);
  responsive.setTabletBreakpoint(tabletBreakpoint);
  responsive.setHDBreakpoint(hdBreakpoint);
  // console.log('page data: ', pages);
};

readPageData();

router.beforeEach((to, from, next) => {
  console.log(to);
  if (from.path !== to.path) {
    let hashElement;
    const contentElement = document.getElementsByClassName("content")[0];
    if (to.hash) {
      hashElement = document.getElementById(to.hash.replace("#", ""));
    }
    if (to.hash && hashElement && contentElement) {
      const y = hashElement.getBoundingClientRect().top - 100;
      // console.log('scrolling to: ', y)
      contentElement.scrollTop = y;
    } else if (contentElement) {
      contentElement.scrollTop = 0;
    }
  }
  // console.log('going to: ', to);
  if (to.path.length > 1 && to.path.endsWith("/")) {
    to.path = to.path.replace(/\/$/, "");
    to.fullPath = to.fullPath.replace(/\/$/, "");
  }
  if (to.fullPath.includes("/#")) {
    to.fullPath = to.fullPath.replace("/#", "#");
  }
  if (to.fullPath.startsWith("/")) {
    responsive.menuOpen.value = false;
    next();
  } else {
    responsive.menuOpen.value = false;
    next(to.path + to.hash);
  }
});

// const setPageDataJSON = () => {
//     const script = document.createElement("script");
//     console.log(prerenderData)
//     script.innerHTML = `var prerenderData = ${JSON.stringify(
//         prerenderData
//     ).replace(/<!--(.*?)-->/g, '')}`;
//     console.log(script);
//     document.head.appendChild(script);
// }

if (isPrerender) {
  // setPageDataJSON();
  app.mount("#app");
  document.dispatchEvent(new Event("render-ready"));
} else {
  app.mount("#app");
}
